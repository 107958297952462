
import { Options, Vue, Watch } from "vue-property-decorator";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import { namespace } from "s-vuex-class";

const MoreThenTwoWrongModule = namespace("theory-questions-more-then-two-wrong");
@Options({
  components: { EducationTabs },
})
export default class MoreThenTwoWrong extends Vue {
  @MoreThenTwoWrongModule.Action("findAll")
  public findMoreThenTwoWrongAction: any;

  @MoreThenTwoWrongModule.Getter("getDataList")
  public moreThenTwoWrong: any;

  @MoreThenTwoWrongModule.Getter("getIsLoading")
  public loading: any;

  public mounted(): void {
    this.loadMoreThenTwoWrong();
  }

  private loadMoreThenTwoWrong(): void {
    this.findMoreThenTwoWrongAction({
      resource: "theory-questions",
      extraPath: "more-than-two-wrong-answers",
      hideSuccessMessage: true,
    });
  }

  private getMaxNumberOfQuestions(question: any): number {
    return question.numberOfWrongAnswers > 5 ? 5 : question.numberOfWrongAnswers;
  }

  private selectQuestion(question: any): void {
    this.$router.push({
      name: "PlaygroundTheoryQuestion",
      params: {
        id: [question.theoryQuestionId],
      },
    });
  }
}
