
import { Options } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import PlaygroundBlock from "@/components/Pages/Playground/PlaygroundBlock.vue";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import { mixins } from "vue-class-component";
import StudentEducationsStoreMixin from "@/mixins/StudentEducations/StudentEducationsStoreMixin.ts";
import MostDifficult from "@/components/Pages/Playground/MostDifficult.vue";
import MoreThenTwoWrong from "@/components/Pages/Playground/MoreThenTwoWrong.vue";
import QuestionSearch from "@/components/Pages/Playground/QuestionSearch.vue";
import TheoryQuestionGroups from "@/components/Pages/Playground/TheoryQuestionGroups.vue";
import ForwardHeader from "@/components/Headers/ForwardHeader.vue";

@Options({
  components: {
    ForwardHeader,
    TheoryQuestionGroups,
    QuestionSearch,
    MoreThenTwoWrong,
    MostDifficult,
    EducationTabs,
    PlaygroundBlock,
    DefaultLayout,
  },
})
export default class Playground extends mixins(StudentEducationsStoreMixin) {}
